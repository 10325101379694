<template>
    <main>
        <div class="h-100">
            <iframe class="fullscreen-element" title="Report Section" src="https://app.powerbi.com/view?r=eyJrIjoiMzYxZGE1YmEtODNiZC00OWFlLWE5MDYtYTllNTA3ZDk5MjQ3IiwidCI6IjQ0MjIwZjgzLWY4ZTctNDhkNS1hZTdjLTQ0MzBiMDBmZmVmYyJ9" frameborder="0" allowFullScreen="true"></iframe>
        </div>
    </main>
</template>

<script>
export default {
  name: "dashboard.vue"
}
</script>

<style scoped>
.fullscreen-element {
    height: 100vh; /* Set the element's height to 100% of the viewport height */
    width: 100%;
}
</style>
